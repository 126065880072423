.shopping-item {
    --min-size: calc(var(--spacing-base) * 4);

    min-height: var(--min-size);
    background-color: #fff;
    display: flex;
    align-items: stretch;
    transition: transform .3s .1s, box-shadow .3s .1s;

    &.is-bought {
        background-color: var(--c-gray-lighter);
    }
    &.is-edited {
        transform: scale(1.025);
        box-shadow: 0 0 10px var(--c-gray);
        transition: transform .2s, box-shadow .1s;
    }

    &__item {
        padding: calc(var(--spacing-base) / 2) 0 calc(var(--spacing-base) / 2)
            var(--spacing-base);
        margin-left: auto;
        flex: 1 1 auto;
        min-width: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__name {
        font-size: 125%;
        display: block;
        min-width: 0;
        white-space: nowrap;
        hyphens: auto;
        word-wrap: break;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__meta {
        font-size: 87.5%;
    }

    &__action-group {
        min-width: 0;
        flex: 0 0 auto;
        display: flex;
    }
    &__action {
        width: var(--min-size);
        min-height: var(--min-size);
        border-left: 1px solid var(--c-gray-light);

        overflow: hidden;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &--mark {
            flex: 1 0 auto;
            position: relative; /* CB */
            
            &:focus {
                outline: none;
            }
            &::-moz-focus-inner {
                border: none;
            }
            &::before {
                content: "";
                display: block;
                width: 1.5em;
                height: 1.5em;
                border: 2px solid var(--c-gray);
                border-radius: 2px;
            }
            &:focus::before {
                border-color: var(--c-gray-dark);
            }
            &::after {
                --scale: 0.9, 0;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 1em;
                height: 1.75em;
                border: solid var(--c-success);
                border-width: 0 7px 7px 0;
                border-radius: 3px;
                transform: rotate(40deg) translate(-64%, -42%) skew(7deg, 2deg)
                    scale(var(--scale));
                transform-origin: 0 100%;
                opacity: 0;
                transition: opacity 0.2s, transform 0.2s;
            }
            .is-bought &::after {
                --scale: 1, 1;
                opacity: 1;
            }
        }

        &--delete {
            --icon-height: 1.25em;
            --icon-width: 4px;
            min-width: 0;
            position: relative; /* CB */
            flex: 0 1 auto;
            background: transparent;

            &::before,
            &::after {
                content: "";
                display: inline-block;
                background-color: var(--c-danger);
                width: var(--icon-width);
                height: var(--icon-height);
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                transform: rotate(45deg);
            }
            &::after {
                transform: rotate(-45deg);
            }
        }

        &--edit {
            --icon-width: .5em;
            --icon-height: 1.25em;
            --icon-color: var(--c-gray);

            font-size: 16px;
            position: relative; /* CB */

            &:focus,
            .is-edited & {
                --icon-color: var(--c-gray-darker);
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
            &::before {
                --tip-height: calc(var(--icon-width) * 1.5);
                width: 0;
                height: 0;
                border: 1px solid;
                border-color: var(--icon-color) transparent transparent transparent;
                border-width: var(--tip-height) calc(var(--icon-width) / 2) 0 calc(var(--icon-width) / 2);
                opacity: .5;
                transform: rotate(45deg) translateY(calc((var(--icon-height) + var(--tip-height)) / 2));
            }
            &::after {
                content: '';
                width: var(--icon-width);
                height: var(--icon-height);
                background-color: var(--icon-color);
                transform: rotate(45deg) translateY(-2px);
            }
        }

        &.is-optional {
            width: 0;
            border-width: 0;
            transition: width .2s;

            .is-editable & {
                border-width: 1px;
                width: var(--min-size);
            }
        }
    }
}