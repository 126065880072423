@mixin a11y-hidden {
    /* see: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html */
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
}