.form {
    &__header {
    }
    &__title {
        margin: 0 0 calc(var(--spacing-base) * 2);
        padding: 0;
        font-size: 125%;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__group {
        &--column {
            margin-top: calc(var(--spacing-base) / 2);
            column-width: 12em;
        }
    }

    &__item {
        margin-bottom: calc(var(--spacing-base) * 2);

        &--radio {
            display: flex;
            align-items: center;

            & [type="radio"] {
                margin: 0 0.35em 0 0;
            }
        }
    }

    &__label {
        display: block;
        font-weight: bold;
        color: var(--c-text-light);
    }

    &__input {
        width: 100%;
        box-sizing: border-box;
        font-size: inherit;
        font-family: inherit;
        border: 2px solid var(--c-gray);
        padding: calc(var(--spacing-base) / 2);
        border-radius: 3px;
        outline: none;
    }
    &__input:focus {
        border-color: var(--c-gray-darker);
    }

    &__radio-group {
    }

    &__action-group {
        padding-top: calc(var(--spacing-base) * 2);
        padding-bottom: var(--spacing-base);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__action {
        margin-left: calc(var(--spacing-base) / 2);
        margin-right: calc(var(--spacing-base) / 2);

        &__submit {
            min-width: 7em;
        }
    }
}