.holder {
    --width: 40rem;
    --spacing: var(--spacing-base);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--spacing);
    padding-right: var(--spacing);
    width: var(--width);
    max-width: 100%;
    box-sizing: border-box;

    &.is-bledoff {
        padding-left: 0;
        padding-right: 0;
    }
}