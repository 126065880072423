.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
        margin: 0;
        padding-top: var(--spacing-base);
        padding-bottom: var(--spacing-base);
        font-size: 100%;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__action {
        font-size: 75%;
    }
}