.cat-list {
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {

    }

    &__title {
        margin: calc(var(--spacing-base) * 2) 0 0;
        padding: 0;
        font-size: 100%;
        font-weight: normal;
        text-transform: uppercase;
    }

    &__content {
        
    }
}